import React from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import dayjs from 'dayjs';
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { TimePicker } from "@mui/x-date-pickers-pro";

export default function TimePickerInput({ name, size, variant, label, helper, control, sx, mask, handleChange, handleBlur, fieldSx }) {
  return (
    <Box sx={{ "& .MuiFormControl-root": { width: 1 }, ...sx }}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                sx={{
                  width: 1,
                  "& .MuiInputBase-root": { backgroundColor: "background.input" },
                  "& .MuiInputBase-root:hover": {
                    backgroundColor: "background.mainLight",
                  },
                  "& .MuiInputBase-root.Mui-disabled": { backgroundColor: "background.white" },
                  "& .MuiInputBase-root.Mui-disabled:hover": { backgroundColor: "background.white" },
                  "& .MuiInputBase-root.Mui-focused": {
                    backgroundColor: "background.main",
                  },
                  ...fieldSx,
                }}
                value={dayjs(field.value)}
                format="hh:mm A"
                onChange={(newValue) => {
                  field.onChange(newValue);
                  if (handleChange) handleChange();
                }}
                onBlur={(newValue) => {
                  field.onBlur(newValue);
                  if (handleBlur) handleBlur(newValue);
                }}
                slotProps={{
                  textField: {
                    color: "secondary",
                    label: label,
                    size: size ?? "medium",
                    variant: variant ?? "outlined",
                    error: Boolean(fieldState.error),
                    helperText: fieldState.error?.message || helper || " ",
                  },
                }}
              />
            </LocalizationProvider>
          );
        }}
      />
    </Box>
  );
}
